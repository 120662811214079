import { createSlice } from "@reduxjs/toolkit";

interface InitialState{
    key:{token:string, refresh_token:string}|null,
    processRegion: string;
}

const initialState:InitialState = {
    key:null,
    processRegion: ""
}

const sessionSlice = createSlice({
    initialState,
    name:"session",
    reducers:{
        setKeys:(state,action)=>({...state,key:action.payload}),
        setProcessRegion: (state, action) => ({ ...state, processRegion: action.payload }),
    },
}
);

export const {setKeys, setProcessRegion} = sessionSlice.actions;
export default sessionSlice.reducer